// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-background-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\BackgroundPageTemplate.jsx" /* webpackChunkName: "component---src-templates-background-page-template-jsx" */),
  "component---src-templates-vehicle-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\VehiclePageTemplate.jsx" /* webpackChunkName: "component---src-templates-vehicle-page-template-jsx" */),
  "component---src-templates-creature-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\CreaturePageTemplate.jsx" /* webpackChunkName: "component---src-templates-creature-page-template-jsx" */),
  "component---src-templates-feat-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\FeatPageTemplate.jsx" /* webpackChunkName: "component---src-templates-feat-page-template-jsx" */),
  "component---src-templates-spell-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\SpellPageTemplate.jsx" /* webpackChunkName: "component---src-templates-spell-page-template-jsx" */),
  "component---src-templates-gear-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\GearPageTemplate.jsx" /* webpackChunkName: "component---src-templates-gear-page-template-jsx" */),
  "component---src-templates-god-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\GodPageTemplate.jsx" /* webpackChunkName: "component---src-templates-god-page-template-jsx" */),
  "component---src-templates-pc-class-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\PcClassPageTemplate.jsx" /* webpackChunkName: "component---src-templates-pc-class-page-template-jsx" */),
  "component---src-templates-pc-sub-class-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\PcSubClassPageTemplate.jsx" /* webpackChunkName: "component---src-templates-pc-sub-class-page-template-jsx" */),
  "component---src-templates-invocation-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\InvocationPageTemplate.jsx" /* webpackChunkName: "component---src-templates-invocation-page-template-jsx" */),
  "component---src-templates-magic-item-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\MagicItemPageTemplate.jsx" /* webpackChunkName: "component---src-templates-magic-item-page-template-jsx" */),
  "component---src-templates-infusion-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\InfusionPageTemplate.jsx" /* webpackChunkName: "component---src-templates-infusion-page-template-jsx" */),
  "component---src-templates-race-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\RacePageTemplate.jsx" /* webpackChunkName: "component---src-templates-race-page-template-jsx" */),
  "component---src-templates-language-page-template-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\templates\\LanguagePageTemplate.jsx" /* webpackChunkName: "component---src-templates-language-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-background-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\background-catalog.jsx" /* webpackChunkName: "component---src-pages-background-catalog-jsx" */),
  "component---src-pages-campaigns-index-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\campaigns\\index.jsx" /* webpackChunkName: "component---src-pages-campaigns-index-jsx" */),
  "component---src-pages-campaigns-oota-index-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\campaigns\\oota\\index.jsx" /* webpackChunkName: "component---src-pages-campaigns-oota-index-jsx" */),
  "component---src-pages-campaigns-toa-generator-random-encounter-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\campaigns\\toa\\generator-random-encounter.jsx" /* webpackChunkName: "component---src-pages-campaigns-toa-generator-random-encounter-jsx" */),
  "component---src-pages-campaigns-toa-generator-treasures-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\campaigns\\toa\\generator-treasures.jsx" /* webpackChunkName: "component---src-pages-campaigns-toa-generator-treasures-jsx" */),
  "component---src-pages-campaigns-toa-index-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\campaigns\\toa\\index.jsx" /* webpackChunkName: "component---src-pages-campaigns-toa-index-jsx" */),
  "component---src-pages-creature-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\creature-catalog.jsx" /* webpackChunkName: "component---src-pages-creature-catalog-jsx" */),
  "component---src-pages-downtime-buy-magic-items-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\downtime-buy-magic-items.jsx" /* webpackChunkName: "component---src-pages-downtime-buy-magic-items-jsx" */),
  "component---src-pages-feat-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\feat-catalog.jsx" /* webpackChunkName: "component---src-pages-feat-catalog-jsx" */),
  "component---src-pages-gear-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\gear-catalog.jsx" /* webpackChunkName: "component---src-pages-gear-catalog-jsx" */),
  "component---src-pages-generator-characters-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\generator-characters.jsx" /* webpackChunkName: "component---src-pages-generator-characters-jsx" */),
  "component---src-pages-generator-loot-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\generator-loot.jsx" /* webpackChunkName: "component---src-pages-generator-loot-jsx" */),
  "component---src-pages-generator-madness-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\generator-madness.jsx" /* webpackChunkName: "component---src-pages-generator-madness-jsx" */),
  "component---src-pages-generator-names-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\generator-names.jsx" /* webpackChunkName: "component---src-pages-generator-names-jsx" */),
  "component---src-pages-generator-sentient-items-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\generator-sentient-items.jsx" /* webpackChunkName: "component---src-pages-generator-sentient-items-jsx" */),
  "component---src-pages-generator-spellbook-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\generator-spellbook.jsx" /* webpackChunkName: "component---src-pages-generator-spellbook-jsx" */),
  "component---src-pages-generator-wild-magic-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\generator-wild-magic.jsx" /* webpackChunkName: "component---src-pages-generator-wild-magic-jsx" */),
  "component---src-pages-god-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\god-catalog.jsx" /* webpackChunkName: "component---src-pages-god-catalog-jsx" */),
  "component---src-pages-index-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infusion-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\infusion-catalog.jsx" /* webpackChunkName: "component---src-pages-infusion-catalog-jsx" */),
  "component---src-pages-invocation-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\invocation-catalog.jsx" /* webpackChunkName: "component---src-pages-invocation-catalog-jsx" */),
  "component---src-pages-language-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\language-catalog.jsx" /* webpackChunkName: "component---src-pages-language-catalog-jsx" */),
  "component---src-pages-magic-item-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\magic-item-catalog.jsx" /* webpackChunkName: "component---src-pages-magic-item-catalog-jsx" */),
  "component---src-pages-pc-class-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\pc-class-catalog.jsx" /* webpackChunkName: "component---src-pages-pc-class-catalog-jsx" */),
  "component---src-pages-race-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\race-catalog.jsx" /* webpackChunkName: "component---src-pages-race-catalog-jsx" */),
  "component---src-pages-roll-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\roll.jsx" /* webpackChunkName: "component---src-pages-roll-jsx" */),
  "component---src-pages-spell-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\spell-catalog.jsx" /* webpackChunkName: "component---src-pages-spell-catalog-jsx" */),
  "component---src-pages-vehicle-catalog-jsx": () => import("A:\\dev\\dm-stuff.ru\\src\\pages\\vehicle-catalog.jsx" /* webpackChunkName: "component---src-pages-vehicle-catalog-jsx" */)
}

